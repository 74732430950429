import * as React from 'react';
import styled, { css } from 'styles/Styled';
import media from 'styles/Media';
import Icon from 'elements/helper/icon';
import Label from 'elements/helper/label';
import Section from 'elements/ui/section';
import EstimationStatusLabel from 'components/connect/estimation/estimationStatusLabel';
import koStrings from '../../../../meta/ko';

const {
  estimationDetailHeader: { requesterSubheader },
} = koStrings;

interface IProps {
  calculationResult: boolean;
  ceRequestVId: string;
  status: string;
  category: string;
  plateNumber: string;
  carBrand: string;
  carModel: string;
  userName: string;
  createdAt: string;
  onLinkToList: () => void;
}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    padding-bottom: 1rem;
    border-bottom: 1px solid ${colors.gray7};

    .estimation-detail-header {
      &__close-button {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 1.5rem;
      }

      &__labels {
        display: flex;
        align-items: center;

        label {
          margin-right: 0.25rem;
        }
      }

      &__title {
        display: flex;
        align-items: center;
        font-size: 1.15rem;
        font-weight: 700;

        span {
          margin-left: 0.35rem;
          font-size: 1rem;
          font-weight: 400;
          color: ${colors.gray2};
        }
      }

      &__time {
        font-size: 0.85rem;
        color: ${colors.gray2};
      }

      ${media.mobile`
        &__close-button {
          display: block;
        }
      `}
    }
  `}
`;

const EstimationDetailHeader: React.FC<IProps> = ({
  calculationResult,
  ceRequestVId,
  status,
  category,
  plateNumber,
  carBrand,
  carModel,
  userName,
  createdAt,
  onLinkToList,
}) => (
  <StyledComponent>
    <span
      className="estimation-detail-header__close-button"
      onClick={onLinkToList}
    >
      <Icon iconName="fa fa-times" />
    </span>
    <Section className="estimation-detail-header__labels" b={0.25}>
      <Label color="info">{category}</Label>
      <EstimationStatusLabel status={status} />
      {!calculationResult && (
        <EstimationStatusLabel status="calculationResult" />
      )}
    </Section>
    <Section className="estimation-detail-header__title" t={0.25} b={0.25}>
      [{ceRequestVId}] {carBrand} {carModel}{' '}
      {!!plateNumber ? `- ${plateNumber}` : ''}{' '}
      <span>
        {userName}
        {requesterSubheader}
      </span>
    </Section>
    <Section className="estimation-detail-header__time" t={0.25}>
      {createdAt}
    </Section>
  </StyledComponent>
);

export default EstimationDetailHeader;
