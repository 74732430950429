import React, { useEffect, useState } from 'react';

import UserProfile from 'elements/helper/userProfile';
import StyledComponent from './ChattingDetailItem.styles';

import styled, { css } from 'styles/Styled';

import { getCardocClientMeta } from 'core/api/estimation';

import compareVersions from 'compare-versions';

const StyledTimestamp = styled.div<{
  markAsRead: boolean;
  isLegacyDevice: boolean;
}>`
  ${({ markAsRead, isLegacyDevice, theme: { colors } }) => {
    let leftOffset = '-50px';
    if (isLegacyDevice) {
      leftOffset = '-50px';
    } else if (markAsRead) {
      leftOffset = '-30px';
    } else {
      leftOffset = '-40px';
    }
    return css`
      .read-status {
        position: absolute;
        left: ${leftOffset};
        text-align: right;
        margin-top: 0.1rem;
        font-size: 10.5px;
        color: ${colors.gray3};
      }
    `;
  }}
`;
interface IProps {
  user: any;
  markAsRead: boolean;
  type: 'user' | 'file';
  myMessage: boolean;
  message: string;
  fileUrl?: string;
  fileName?: string;
  createdAt: string;
  onShowImage: (imageSrc: string) => void;
}

const ChattingDetailItem: React.FC<IProps> = ({
  user,
  markAsRead,
  type,
  myMessage,
  message,
  fileUrl,
  fileName,
  createdAt,
  onShowImage,
}) => {
  const [isLegacyDevice, setIsLegacyDevice] = useState(true);
  useEffect(() => {
    const asyncGetUserMeta = async () => {
      const {
        metaData: { userkey },
      } = user;
      let userMetaResponse = null;
      try {
        userMetaResponse = await getCardocClientMeta(userkey);
      } catch (error) {
        console.log('error: ', error);
      }
      const userMeta = userMetaResponse?.data;
      const device = userMeta?.device;
      if (device === 'ios') {
        const cookies = userMeta?.cookie.split(',');
        const appVerCookie = cookies[1];
        const appVer = appVerCookie.slice(7);
        const isUnsupportedLegacyVersion = compareVersions(appVer, '5.3.0');
        if (isUnsupportedLegacyVersion < 0) {
          setIsLegacyDevice(true);
        } else {
          setIsLegacyDevice(false);
        }
      } else {
        setIsLegacyDevice(false);
      }
      return userMeta;
    };
    asyncGetUserMeta();
  }, [user]);

  return (
    <StyledComponent myMessage={myMessage}>
      {!myMessage && <UserProfile />}
      <div className="content">
        <div className="wrap">
          {type === 'user' && <div className="box">{message}</div>}
          {type === 'file' && fileUrl && (
            <img
              src={fileUrl}
              alt={fileName || ''}
              title="원본 이미지 보기"
              onClick={() => onShowImage(fileUrl)}
            />
          )}
          <StyledTimestamp
            markAsRead={markAsRead}
            isLegacyDevice={isLegacyDevice}
          >
            {myMessage &&
              (isLegacyDevice ? (
                <div className="read-status">알수 없음</div>
              ) : (
                <div className="read-status">
                  {markAsRead ? '읽음' : '안읽음'}
                </div>
              ))}
            <time>{createdAt}</time>
          </StyledTimestamp>
        </div>
      </div>
    </StyledComponent>
  );
};

export default ChattingDetailItem;
