import { atom } from 'recoil';

const appStateAtom = atom({
  key: 'appState', // unique ID (with respect to other atoms/selectors)
  default: {
    shouldReloadChats: false,
  }, // default value (aka initial value)
});

export default appStateAtom;
