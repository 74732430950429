import * as React from 'react';
import moment from 'moment';
import { numberFormat } from 'core/function/Formatter';
import { getEstStatus } from 'core/function/dataParser';
import { IContProp } from 'containers/connect/chatting/chattingEstCont/interface';
import Pagination from 'components/base/pagination';
import ChattingDetailTemp from 'components/connect/chatting/chattingDetailTemp';
import ChattingEstItem from 'components/connect/chatting/chattingEstItem';

const ChattingEstPage: React.FC<IContProp> = ({
  page,
  itemCount,
  perPage,

  estimations,

  onChangePage,
  updateMemoMetaThenUpdateAssignee,
}) => {
  const estimationsMapped =
    estimations.length !== 0 ? (
      <ChattingDetailTemp.EstReq>
        {estimations.map(
          (
            {
              ceInsuranceDetails,
              ceRequestVId,
              connectShopReview,
              connectEstimation: {
                id,
                chatChannelUrl,
                responsibility,
                suggestedAmount,
                ceRequest: {
                  birthDate,
                  gender,
                  createdAt,
                  cdServiceType: { svc_name_ko },
                  userCar: {
                    brand: { brandnameko },
                    carmodel: { modelname },
                  },
                  authUser: { name },
                  estimationInquiries,
                },
              },
              memoMeta,
              finalizedInsuranceDetails,
            }: any,
            indexToUpdate: any,
          ) => {
            return (
              <ChattingEstItem
                birthDate={birthDate}
                gender={gender}
                key={id}
                ceInsuranceDetails={ceInsuranceDetails}
                requestId={ceRequestVId}
                category={svc_name_ko}
                carBrand={brandnameko}
                carModel={modelname}
                status={getEstStatus({ chatChannelUrl, connectShopReview })}
                suggestedAmount={numberFormat(suggestedAmount)}
                answers={estimationInquiries
                  .map(({ answer }: any) => answer)
                  .join(', ')}
                inquiries={estimationInquiries}
                userName={name}
                memoMeta={memoMeta}
                finalizedInsuranceDetails={finalizedInsuranceDetails}
                connectEstimationId={id}
                responsibility={responsibility}
                createdAt={moment(createdAt).format('YYYY.MM.DD HH:mm')}
                updateMemoMetaThenUpdateAssignee={
                  updateMemoMetaThenUpdateAssignee
                }
                indexToUpdate={indexToUpdate}
              />
            );
          },
        )}
        {itemCount > perPage && (
          <div className="my-4">
            <Pagination
              currentPage={page}
              itemCount={itemCount}
              perPage={perPage}
              perBlock={3}
              onChange={onChangePage}
            />
          </div>
        )}
      </ChattingDetailTemp.EstReq>
    ) : null;
  return estimationsMapped;
};

export default ChattingEstPage;
