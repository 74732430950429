import * as React from 'react';
import styled, { css } from 'styles/Styled';
import EstimationSection from 'components/connect/estimation/estimationSection';

interface IProps {
  birthDate: string;
  gender: string;
  items: { type: string; question: string; answer: string }[];
  userTelecomString?: string;
}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    padding: 0.5rem;
    background-color: ${colors.gray7};

    dl {
      padding: 0.75rem;

      dt {
        font-size: 1.05rem;
        font-weight: 600;
      }

      dd {
        margin-top: 0.5rem;
      }
    }
  `}
`;

const EstimationDetailQna: React.FC<IProps> = ({
  items,
  userTelecomString,
}) => {
  const questionsMarkup = items.map(({ type, question, answer }, index) => {
    return (
      type !== 'q5' && (
        <dl key={type}>
          <dt>{question}</dt>
          <dd>
            - {answer}{' '}
            {type === 'q4' &&
              (userTelecomString
                ? `(${userTelecomString})`
                : `(통신사 미입력 고객)`)}
          </dd>
        </dl>
      )
    );
  });
  return (
    <EstimationSection title="견적 요청 내용">
      <StyledComponent>{questionsMarkup}</StyledComponent>
    </EstimationSection>
  );
};

export default EstimationDetailQna;
