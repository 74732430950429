import Sendbird from 'sendbird';

// import { getChannel } from 'core/sdk/sendbird/groupChannel';

export const updateChannelHanlder = (
  connection: Sendbird.SendBirdInstance,
  callback: Function,
) => {
  if (!connection) return;

  connection.removeAllChannelHandlers();
  connection.removeAllConnectionHandlers();

  const channelHandler = new connection.ChannelHandler();
  channelHandler.onChannelChanged = channel => {
    callback(channel);
  };
  connection.addChannelHandler('chattingListHandler', channelHandler);
};

// export const messageReceiveHandler = (
//   connection: Sendbird.SendBirdInstance,
//   channelUrl: string,
//   callback: Function,
// ) => {
//   if (!connection) return;
//   const ChannelHandler = new connection.ChannelHandler();
//   ChannelHandler.onMessageReceived = (_, response) => {
//     if (response.channelUrl === channelUrl) {
//       callback(response);
//     }
//   };
//   ChannelHandler.onReadReceiptUpdated = async channel => {
//     console.log('onReadReceiptUpdated channel: ', channel);
//     const channelRes: any = await getChannel(connection, channelUrl);
//     console.log('eventHandler channelRes: ', channelRes);
//     // markAsRead
//     // alert('onReadReceiptUpdated');

//     channelRes.markAsRead();
//   };
//   connection.addChannelHandler(channelUrl, ChannelHandler);
// };

export const removeChannelHandler = (
  connection: Sendbird.SendBirdInstance,
  channelUrl: string,
) => {
  if (!connection) return;
  connection.removeChannelHandler(channelUrl);
};
