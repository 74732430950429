import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  searchkeywordEstimationReq,
  searchStatusEstimationReq,
  checkEstimation,
} from 'core/api/estimation';

import theme from 'styles/Theme';
import Loading from 'elements/helper/loading';
import Empty from 'elements/helper/empty';
import Section from 'elements/ui/section';
import Pagination from 'components/base/pagination';
import EstimationTemp from 'components/connect/estimation/estimationTemp';
import EstimationSearch from 'components/connect/estimation/estimationSearch';
import EstimationListItem from 'components/connect/estimation/estimationListItem';
import { parseEstimation } from 'core/function/dataParser';
import { IContProp } from 'containers/connect/estimation/estimationCont/interface';

import koStrings from '../../../../meta/ko';

const {
  clientSearch: { inputDropdowns },
} = koStrings;

const { useRef, useState, useEffect, useCallback } = React;

/**
 * TODO
 *
 * 견적서 목록 갱신을 위해 30초마다 한번씩 목록을 조회 하고 있지만,
 * 나중에는 소켓 통신으로 실시간 갱신을 할 수 있도록 처리해야 한다.
 */
const EstimationListCont: React.FC<IContProp> = ({
  reloadTrigger,
  selectedId,
  onViewDetail,
}) => {
  const [state, setState] = useState<{
    pending: boolean;
    page: number;
    estimation: any;
  }>({
    pending: false,
    page: 1,
    estimation: null,
  });

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakPoint.mobile}px)`,
  });

  const ref = useRef({
    /**
     * 검색 구분 - 상태 검색과 키워드 검색 API가 따로 있기 때문에
     * 이걸 기억하고 있어야 페이지 이동시 처리할 수 있다.
     */
    searchDiv: 'status', // keyword or status

    // 검색 폼
    searchForm: {
      searchType: inputDropdowns[0].value,
      searchKeyword: '',
      estStatus: '',
    },
  });

  // state 저장용 레퍼런스
  const stateRef = useRef<any>(null);

  // 자동 갱신 타이머
  const refreshTimer = useRef<number | null>(null);

  // 자동 갱신 타이머
  const setRefreshInterval = useCallback(() => {
    return setInterval(async () => {
      // 상세를 보고 있지 않은 경우에만 refresh
      if (!!stateRef.current.selectedId) return;
      try {
        let response;
        if (ref.current.searchDiv === 'status') {
          response = await searchStatusEstimationReq({
            page: stateRef.current.page,
            status: ref.current.searchForm.estStatus as any,
          });
        }

        if (ref.current.searchDiv === 'keyword') {
          const { searchType, searchKeyword } = ref.current.searchForm;
          response = await searchkeywordEstimationReq({
            page: stateRef.current.page,
            [searchType]: searchKeyword,
          });
        }

        if (response) {
          setState({
            ...stateRef.current,
            estimation: response.data,
            page: response.data.pageable.pageNumber + 1,
          });
        }
      } catch {
        // 요청 실패
      }
    }, 30000);
  }, []);

  // 견적 요청서 상태 검색
  const handleSearchStatus = useCallback(
    async page => {
      try {
        // 리스트를 다시 조회할 때는 타이머를 잠시 해제 해둔다.
        if (refreshTimer.current) {
          clearInterval(refreshTimer.current as number);
        }

        ref.current.searchDiv = 'status';
        setState({ ...state, pending: true });

        const response = await searchStatusEstimationReq({
          page,
          status: ref.current.searchForm.estStatus as any,
        });

        setState({
          ...state,
          pending: false,
          estimation: response.data,
          page: response.data.pageable.pageNumber + 1,
        });

        // 리스트 조회 완료후 자동 갱신 타이머를 다시 추가 한다.
        refreshTimer.current = setRefreshInterval();
      } catch {
        setState({ ...state, pending: false });
      }
    },
    [state, setRefreshInterval],
  );

  // 견적 요청서 키워드 검색
  const handleSearchKeyword = useCallback(
    async page => {
      try {
        // 리스트를 다시 조회할 때는 타이머를 잠시 해제 해둔다.
        if (refreshTimer.current) {
          clearInterval(refreshTimer.current as number);
        }

        const { searchType, searchKeyword } = ref.current.searchForm;
        ref.current.searchDiv = 'keyword';
        setState({ ...state, pending: true });

        const response = await searchkeywordEstimationReq({
          page,
          [searchType]: searchKeyword,
        });

        setState({
          ...state,
          pending: false,
          estimation: response.data,
          page: response.data.pageable.pageNumber + 1,
        });

        // 리스트 조회 완료후 자동 갱신 타이머를 다시 추가 한다.
        refreshTimer.current = setRefreshInterval();
      } catch {
        setState({ ...state, pending: false });
      }
    },
    [state, setRefreshInterval],
  );

  // 견적 요청서 키워드 검색
  const handleSubmit = useCallback(() => {
    if (!ref.current.searchForm.searchKeyword) {
      // 키워드가 입력되지 않았다면 전체 목록을 요청
      ref.current.searchForm.estStatus = '';
      setTimeout(() => {
        handleSearchStatus(1);
      });
      return;
    }
    setTimeout(() => {
      handleSearchKeyword(1);
    });
  }, [handleSearchKeyword, handleSearchStatus]);

  // 검색 폼 입력
  const handleInputSearch = useCallback(
    nextState => {
      if (ref.current.searchForm.estStatus !== nextState.estStatus) {
        // 견적 요청서 상태 변경시 새로운 목록을 요청
        setTimeout(() => {
          handleSearchStatus(1);
        });
      }
      ref.current.searchForm = nextState;
    },
    [handleSearchStatus],
  );

  // 해당 페이지 데이터 불러오기
  const handleLoadItems = useCallback(
    async nextPage => {
      if (ref.current.searchDiv === 'status') {
        await handleSearchStatus(nextPage);
      }
      if (ref.current.searchDiv === 'keyword') {
        await handleSearchKeyword(nextPage);
      }
    },
    [handleSearchStatus, handleSearchKeyword],
  );

  // 견적서 상새
  const handleView = useCallback(
    (requestId, estimationId?) => {
      onViewDetail(requestId);
      if (!estimationId) return;

      // 확인 안한 견적서에 대해 확인 처리
      checkEstimation(estimationId);
      setState({
        ...state,
        estimation: {
          ...state.estimation,
          content: state.estimation.content.map((item: any) =>
            item?.connectEstimation?.id === estimationId
              ? {
                  ...item,
                  connectEstimation: {
                    ...item.connectEstimation,
                    checkYn: 'Y',
                  },
                }
              : item,
          ),
        },
      });
    },
    [state, onViewDetail],
  );

  useEffect(() => {
    (async () => {
      // 마운트시 전체 목록 조회
      await handleSearchStatus(1);
    })();

    return () => {
      if (refreshTimer.current) {
        // 언마운트시 갱신 타이머 삭제
        clearInterval(refreshTimer.current as number);
      }
    };

    // eslint-disable-next-line
  }, []);

  // 리로드 트리거
  useEffect(() => {
    if (reloadTrigger === 0) return;
    handleLoadItems(state.page);
    // eslint-disable-next-line
  }, [reloadTrigger]);

  // state 레퍼런스 저장
  useEffect(() => {
    stateRef.current = {
      ...state,
      selectedId,
    };
  }, [state, selectedId]);

  // 모바일에서는 견적서 상세를 볼 때는 리스트를 노출하지 않음
  return isMobile && !!selectedId ? null : (
    <EstimationTemp.List>
      <Loading show={state.pending}>
        받은 견적 목록을 조회하고 있습니다.
      </Loading>
      <EstimationSearch
        dataSource={ref.current.searchForm}
        callback={handleInputSearch}
        onSubmit={handleSubmit}
      />
      <EstimationTemp.List.Items>
        {!!state.estimation && (
          <>
            {state.estimation.content.length !== 0 ? (
              <>
                {state.estimation.content.map((estItem: any) => {
                  const {
                    requestId,
                    estimationId,
                    ceRequestVId,
                    status,
                    category,
                    plateNumber,
                    carBrand,
                    carModel,
                    userName,
                    memoMeta,
                    assignee,
                    isChecked,
                    createdAt,
                    estimationInquiries,
                    calculationResult,
                  } = parseEstimation(estItem);

                  const estimationInquiriesToShow = [
                    ...estimationInquiries.slice(0, 3),
                    ...estimationInquiries.slice(4),
                  ];

                  const answersToShow = estimationInquiriesToShow
                    .map(({ answer, type }: any) => {
                      if (type === 'q5') {
                        return null;
                      }
                      return answer;
                    })
                    .join(', ');

                  return (
                    <EstimationListItem
                      calculationResult={calculationResult}
                      key={requestId}
                      active={selectedId === requestId}
                      estimationId={estimationId}
                      ceRequestVId={ceRequestVId}
                      status={status}
                      category={category}
                      carBrand={carBrand}
                      carModel={carModel}
                      plateNumber={plateNumber}
                      answers={answersToShow}
                      userName={userName}
                      memoMeta={memoMeta}
                      assignee={assignee}
                      isChecked={isChecked}
                      createdAt={createdAt}
                      onClick={() =>
                        handleView(requestId, !isChecked && estimationId)
                      }
                    />
                  );
                })}
                {state.estimation.totalElements >
                  state.estimation.pageable.pageSize && (
                  <Section t={2} b={2}>
                    <Pagination
                      currentPage={state.estimation.pageable.pageNumber + 1}
                      itemCount={state.estimation.totalElements}
                      perPage={state.estimation.pageable.pageSize}
                      perBlock={5}
                      onChange={handleLoadItems}
                    />
                  </Section>
                )}
              </>
            ) : (
              <Empty full>받은 견적 요청이 없습니다.</Empty>
            )}
          </>
        )}
      </EstimationTemp.List.Items>
    </EstimationTemp.List>
  );
};

export default EstimationListCont;
