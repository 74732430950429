import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import AppProvider from 'core/context/appContext';
import Routes from 'core/route';
import GlobalStyles from 'styles/Global';
import theme from 'styles/Theme';
import 'styles/scss/index.scss';

import { RecoilRoot } from 'recoil';

const App: React.FC = () => {
  return (
    <AppProvider>
      <RecoilRoot>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Routes />
          </ThemeProvider>
        </BrowserRouter>
      </RecoilRoot>
    </AppProvider>
  );
};

export default App;
