import * as React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import toastr from 'toastr';

import useFetch from 'core/hooks/UseFetch';
import {
  getEstimationReq,
  getMemoMeta,
  createEstimation,
  updateMemoMeta,
  updateAssignee,
  getFinalizedCustomerInsuranceDetails,
  getPossibleInsuranceCompanies,
} from 'core/api/estimation';

import { parseEstimation } from 'core/function/dataParser';
import { IContProp } from 'containers/connect/estimation/estimationCont/interface';
import { CONNECT_CHATTING } from 'core/consts/Path';

import Loading from 'elements/helper/loading';
import Section from 'elements/ui/section';
import EstimationTemp from 'components/connect/estimation/estimationTemp';
import EstimationDetailHeader from 'components/connect/estimation/estimationDetailHeader';
import EstimationDetailQna from 'components/connect/estimation/estimationDetailQna';
import EstimationDetailMemo from 'components/connect/estimation/estimationDetailMemo';
import EstimationRequest from 'components/connect/estimation/estimationRequest';
import EstimationReview from 'components/connect/estimation/estimationReview';
import EstimationAssignResponsibility from 'components/connect/estimation/estimationAssignResponsibility/EstimationAssignResponsibility';
import EstimationFinalizedDetails from 'components/connect/estimation/EstimationFinalizedDetails/EstimationFinalizedDetails';

import koStrings from '../../../../meta/ko';

const {
  estimationDetailContainer: {
    toastr: {
      estimationDetail: { fetchFail },
      getInsuranceCompanies: { fetchFail: getInsuranceCompaniesFetchFail },
      createEstimation: { createSuccess, createFail },
      memo: { updateSuccess, updateFail },
      assignee: {
        updateSuccess: assigneeUpdateSuccess,
        updateFail: assigneeUpdateFail,
      },
    },
    loading: { message },
  },
} = koStrings;

const { useState, useMemo, useEffect, useCallback } = React;

const EstimationDetailCont: React.FC<IContProp> = ({
  selectedId,
  onViewList,
  onReloadList,
}) => {
  const history = useHistory();
  const [state, setState] = useState<{ reqItem: any }>({
    reqItem: null,
  });

  // 견적 요청서 조회 API
  const [onGetEstimationReq, reqPending] = useFetch(getEstimationReq, null);
  // new memo meta 조회 API
  const [onGetMemoMetaReq, getMemoMetaPending] = useFetch(getMemoMeta, null);

  // 견적서 발송 API
  const [
    onCreateEstimation,
    estimationPending,
    ,
    createEstimationError,
  ] = useFetch(createEstimation, null);

  useEffect(() => {
    const data = createEstimationError?.response?.data;
    if (createEstimationError) {
      if (data?.status === 409) {
        toastr.error(data?.message);
      } else {
        toastr.error(createFail);
      }
    }
  }, [createEstimationError]);

  // 메모 저장 API
  const [onUpdateMemo, memoUpdatePending] = useFetch(updateMemoMeta, null);
  // 담당자 저장 API
  const [onUpdateAssignee, assigneePending] = useFetch(updateAssignee, null);

  // GET finalized insurance details
  const [ongetFinalizedCustomerInsuranceDetails] = useFetch(
    getFinalizedCustomerInsuranceDetails,
    null,
  );
  const [
    finalizedCustomerInsuranceDetails,
    setFinalizedCustomerInsuranceDetails,
  ] = useState({});

  const getInsuranceDetails = useCallback(
    async requestId => {
      const response = await ongetFinalizedCustomerInsuranceDetails(requestId);
      if (response) {
        setFinalizedCustomerInsuranceDetails(response);
      } else {
        setFinalizedCustomerInsuranceDetails({});
      }
    },
    [ongetFinalizedCustomerInsuranceDetails],
  );

  // 견적서 불러오기
  const fetchEstimate = useCallback(
    async requestId => {
      const response = await onGetEstimationReq(requestId);
      if (response) {
        const { id: connectEstimationId } = response.connectEstimation;
        setEstimationId(connectEstimationId);
        const memoMeta = await onGetMemoMetaReq(connectEstimationId);
        setState({
          ...state,
          reqItem: {
            ...response,
            memoMeta,
          },
        });
      } else {
        toastr.error(fetchFail);
        onViewList();
      }
    },
    [state, onGetEstimationReq, onViewList, onGetMemoMetaReq],
  );

  // 견적서 발송
  const handleCreateEstimation = useCallback(
    async suggestAmount => {
      if (!state.reqItem?.connectEstimation?.id) return;

      const response = await onCreateEstimation({
        ...suggestAmount,
        estimationId: state.reqItem.connectEstimation.id,
      });

      if (response) {
        toastr.success(createSuccess);

        // 목록, 상세 갱신
        onReloadList();

        // state 업데이트
        setState({
          ...state,
          reqItem: response,
        });
      }
    },
    [state, onCreateEstimation, onReloadList],
  );

  // 메모 저장
  // TODO: Figure out if `handleUpdateMemo` below is only logic required to update memoMeta.
  // Seemingly duplicate code also exists in
  // > src/containers/connect/chatting/chattingEstimateContainer/ChattingEstimateContainer.tsx
  // in function handleSubmitMemoMeta.
  const handleUpdateMemo = useCallback(
    async memoMeta => {
      if (!state.reqItem?.connectEstimation?.id) return;
      if (await onUpdateMemo(state.reqItem.connectEstimation.id, memoMeta)) {
        const { id: connectEstimationId } = state.reqItem.connectEstimation;
        const memoMeta = await onGetMemoMetaReq(connectEstimationId);
        setState(state => ({
          ...state,
          reqItem: {
            ...state.reqItem,
            memoMeta,
          },
        }));
        // 업데이트 성공시 리스트 갱신
        toastr.success(updateSuccess);
        // 목록, 상세 갱신
        onReloadList();
      } else {
        toastr.error(updateFail);
      }
    },
    [state.reqItem, onUpdateMemo, onReloadList, onGetMemoMetaReq],
  );

  const handleUpdateAssignee = useCallback(
    async value => {
      if (!state.reqItem?.connectEstimation?.id) return;
      const updateAssignee = await onUpdateAssignee(
        state.reqItem.connectEstimation.id,
        value,
      );
      if (updateAssignee) {
        toastr.success(assigneeUpdateSuccess);
        // state 업데이트
        setState({
          ...state,
          reqItem: {
            ...state.reqItem,
            connectEstimation: {
              ...updateAssignee,
            },
          },
        });
        // 업데이트 성공시 리스트 갱신
        // 목록, 상세 갱신
        onReloadList();
      } else {
        toastr.error(assigneeUpdateFail);
      }
    },
    [state, onUpdateAssignee, onReloadList],
  );

  // 채팅방 바로 가기
  const handleLinkToChat = useCallback(
    cUrl => {
      history.push(`${CONNECT_CHATTING}/${cUrl}`);
    },
    [history],
  );

  useEffect(() => {
    setState({ ...state, reqItem: null });
    fetchEstimate(selectedId);
    // eslint-disable-next-line
  }, [selectedId]);

  const [estimationId, setEstimationId] = useState(0);

  useEffect(() => {
    if (estimationId) {
      getInsuranceDetails(estimationId);
    }
  }, [estimationId, getInsuranceDetails]);

  const [possibleInsuranceCompanies, setPossibleInsuranceCompanies] = useState(
    [],
  );

  useEffect(() => {
    const asyncGetPossibleInsuranceCompanies = async () => {
      try {
        const response = await getPossibleInsuranceCompanies();
        const listOfPossibleInsuranceCompanies = response.data.result;
        setPossibleInsuranceCompanies(listOfPossibleInsuranceCompanies);
      } catch (error) {
        toastr.error(getInsuranceCompaniesFetchFail);
      }
    };
    asyncGetPossibleInsuranceCompanies();
  }, []);

  const estDetailComp = useMemo(() => {
    if (!state.reqItem) return null;
    const {
      birthDate,
      gender,
      multipleInsuranceEstimations,
      ceRequestVId,
      status,
      category,
      plateNumber,
      carBrand,
      carModel,
      userName,
      memoMeta,
      assignee,
      review,
      channelUrl,
      suggestedAmount,
      createdAt,
      estimationInquiries,
      userTelecomString,
      calculationResult,
    } = parseEstimation(state.reqItem);

    return (
      <>
        {/* 견적 요청서 상세 */}
        <EstimationTemp.Receive>
          <Section b={0.75}>
            <EstimationDetailHeader
              calculationResult={calculationResult}
              ceRequestVId={ceRequestVId}
              status={status}
              category={category}
              plateNumber={plateNumber}
              carBrand={carBrand}
              carModel={carModel}
              userName={userName}
              createdAt={createdAt}
              onLinkToList={onViewList}
            />
          </Section>
          <Section t={0.75} b={0.75}>
            <EstimationDetailQna
              birthDate={birthDate}
              gender={gender}
              items={estimationInquiries}
              userTelecomString={userTelecomString}
            />
          </Section>
          <Section t={0.75} b={0.75}>
            <EstimationDetailMemo
              pending={memoUpdatePending || getMemoMetaPending}
              memoMeta={memoMeta}
              callback={handleUpdateMemo}
              possibleInsuranceCompanies={possibleInsuranceCompanies}
            />
          </Section>
        </EstimationTemp.Receive>

        <EstimationTemp.Request>
          {/* 후기 */}
          {review && (
            <Section t={1}>
              <EstimationReview
                score={review.score}
                review={review.review}
                createdAt={moment(review.createdAt).format(
                  'YYYY-MM-DD HH:mm:ss',
                )}
              />
            </Section>
          )}
          <Section t={0.75} b={0.75}>
            <EstimationAssignResponsibility
              pending={assigneePending}
              defaultValue={assignee}
              callback={handleUpdateAssignee}
            />
          </Section>
          <Section t={0.75} b={0.75}>
            <EstimationFinalizedDetails
              finalizedCustomerInsuranceDetails={
                finalizedCustomerInsuranceDetails
              }
            />
          </Section>
          {/* 견적 메세지 보내기 */}
          <Section b={1}>
            <EstimationRequest
              pending={estimationPending}
              channelUrl={channelUrl}
              suggestedAmount={suggestedAmount}
              onCreateEstimation={handleCreateEstimation}
              onLinkToChat={handleLinkToChat}
              possibleInsuranceCompanies={possibleInsuranceCompanies}
              multipleInsuranceEstimations={multipleInsuranceEstimations}
            />
          </Section>
        </EstimationTemp.Request>
      </>
    );
  }, [
    state.reqItem,
    estimationPending,
    memoUpdatePending,
    getMemoMetaPending,
    handleCreateEstimation,
    handleLinkToChat,
    handleUpdateMemo,
    onViewList,
    assigneePending,
    handleUpdateAssignee,
    finalizedCustomerInsuranceDetails,
    possibleInsuranceCompanies,
  ]);

  return (
    <EstimationTemp.Detail>
      <Loading show={reqPending || getMemoMetaPending}>{message}</Loading>
      {estDetailComp}
    </EstimationTemp.Detail>
  );
};

export default EstimationDetailCont;
