import * as React from 'react';
import styled, { css } from 'styles/Styled';
import { darken } from 'polished';

import media from 'styles/Media';
import Icon from 'elements/helper/icon';
import Label from 'elements/helper/label';
import EstimationStatusLabel from 'components/connect/estimation/estimationStatusLabel';

interface IProps {
  calculationResult: boolean;
  active: boolean;
  ceRequestVId: string;
  estimationId: string;
  status: string;
  category: string;
  plateNumber: string;
  carBrand: string;
  carModel: string;
  answers: string;
  userName: string;
  memoMeta: { description: string };
  assignee: string;
  isChecked: boolean;
  createdAt: string;
  onClick: () => void;
}

const StyledComponent = styled.figure<{ active: boolean; isChecked: boolean }>`
  ${({ active, isChecked, theme: { sizes, colors } }) => css`
    padding: 1.25rem 1rem;
    border-bottom: 1px solid ${colors.border};
    border-radius: ${sizes.borderRadius};
    transition: background-color 500ms ease;

    ${!isChecked &&
      css`
        background-color: ${darken(0.05, colors.blueLightGray)} !important;
      `}

    ${active &&
      css`
        background-color: ${colors.gray6} !important;
      `}

    &:hover {
      background-color: ${colors.gray8};
      cursor: pointer;
    }

    .item-header {
      display: flex;

      .labels {
        margin-right: 0.5rem;

        label {
          margin-right: 3px;
          padding: 0.3rem 0.5rem;
          font-size: 0.85rem;
        }
      }

      time {
        margin-left: auto;
        font-size: 0.825rem;
        color: ${colors.gray3};
      }
    }

    .text {
      margin-top: 0.75rem;
      display: flex;
      align-items: center;

      .req-id {
        font-weight: 700;
      }

      .user-car {
        margin-left: 0.25rem;
        font-weight: 700;
      }

      .user-name {
        margin-left: 0.25rem;
        font-size: 0.925rem;
        font-weight: 400;
        color: ${colors.gray2};
      }
    }

    .message {
      margin-top: 0.35rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: ${colors.gray1};
    }

    .memo {
      margin-top: 0.75rem;
      padding: 0.75rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border: 1px solid ${colors.gray6};
      background-color: ${colors.gray9};
      font-size: 0.875rem;
      color: ${colors.gray2};

      svg {
        margin-right: 0.5rem;
        color: ${colors.gray4};
      }
    }

    .assignee {
      font-weight: 500;
      color: black;
    }

    ${media.mobile`
      border: 1px solid ${colors.border};
      border-bottom-width: 0;

      &:last-of-type{
        border-bottom-width: 1px;
      }
    `}
  `}
`;

const EstimationListItem: React.FC<IProps> = ({
  calculationResult,
  active,
  ceRequestVId,
  status,
  category,
  plateNumber,
  carBrand,
  carModel,
  answers,
  userName,
  memoMeta,
  assignee,
  isChecked,
  createdAt,
  onClick,
}) => {
  let showMemoOrAssignee = false;
  let memoOrAssigneeDivider = null;
  if (memoMeta?.description || assignee) {
    showMemoOrAssignee = true;
  }
  if (memoMeta?.description && assignee) {
    memoOrAssigneeDivider = ' / ';
  }

  return (
    <StyledComponent active={active} isChecked={isChecked} onClick={onClick}>
      <div className="item-header">
        <div className="labels">
          <Label color="info">{category}</Label>
          <EstimationStatusLabel status={status} />
          {!calculationResult && (
            <EstimationStatusLabel status="calculationResult" />
          )}
        </div>
        <time>{createdAt}</time>
      </div>
      <p className="text">
        <strong className="req-id ">[{ceRequestVId}]</strong>{' '}
        <strong className="user-car">
          {carBrand} {carModel} {!!plateNumber ? `- ${plateNumber}` : ''}
        </strong>{' '}
        <span className="user-name">{userName}님</span>
      </p>
      <p className="message">{answers}</p>
      {showMemoOrAssignee && (
        <p className="memo">
          <Icon iconName="fa fa-exclamation-circle" />
          {assignee && <span className="assignee">{assignee}</span>}
          {memoOrAssigneeDivider}
          {memoMeta?.description}
        </p>
      )}
    </StyledComponent>
  );
};

export default EstimationListItem;
