import moment from 'moment';

/**
 * 견적서 상태
 */
export const getEstStatus = ({
  chatChannelUrl,
  connectShopReview,
}: {
  chatChannelUrl?: string;
  connectShopReview?: any;
}) => {
  if (!!connectShopReview) {
    return 'complete';
  }

  if (!!chatChannelUrl) {
    return 'ing';
  }

  return 'wait';
};

/**
 * 견적서 데이터 파싱
 */
export const parseEstimation = (data: any) => {
  const {
    ceInsuranceDetails: multipleInsuranceEstimations,
    ceRequestVId,
    connectShopReview,
    connectEstimation: {
      // TODO: connectEstimation id and ceRequest id - prevent duplication through better naming
      id,
      chatChannelUrl,
      // NOTE: description (old memo) removed, and responsibility (assignee in frontend) added
      responsibility,
      suggestedAmount,
      checkYn,
      ceRequest: {
        // TODO: connectEstimation id and ceRequest id - prevent duplication through better naming
        birthDate,
        gender,
        id: requestId,
        createdAt,
        cdServiceType: { svc_name_ko },
        userCar: {
          platenum,
          brand: { brandnameko },
          carmodel: { modelname },
        },
        authUser: { name },
        estimationInquiries,
        carrier,
        calculationResult,
      },
    },
    // NOTE: memoMeta replaces description (old memo), and includes additional info (vin etc.)
    memoMeta,
  } = data;

  const koreaTelecomArray = [
    'SKT',
    'KT',
    'LG U+',
    '알뜰폰(SKT)',
    '알뜰폰(KT)',
    '알뜰폰(LG U+)',
  ];

  return {
    birthDate,
    gender,
    calculationResult,
    multipleInsuranceEstimations,
    requestId,
    suggestedAmount,
    estimationInquiries: Array.isArray(estimationInquiries)
      ? estimationInquiries
      : [],
    userTelecomString: koreaTelecomArray[carrier],
    estimationId: id,
    ceRequestVId,
    category: svc_name_ko,
    plateNumber: platenum,
    carBrand: brandnameko,
    carModel: modelname,
    userName: name,
    memoMeta,
    assignee: responsibility,
    channelUrl: chatChannelUrl,
    review: connectShopReview,
    isChecked: checkYn === 'Y',
    isStartedChat: !!chatChannelUrl,
    createdAt: moment(createdAt).format('YYYY.MM.DD HH:mm'),
    status: getEstStatus({ chatChannelUrl, connectShopReview }),
  };
};
